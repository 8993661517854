<template>
  <div @click="dateMenu = true">
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-on="on"
          hide-details="auto"
          filled
          :class="$attrs['class']"
          :disabled="disabled"
          :rules="[...(rules || []), $rules.date]"
          :label="label"
          :value="value"
          @input="$emit('input', $event)"
          v-mask="['##/##/####']"
          placeholder="00/00/0000"
          :clearable="clearable"
        />
      </template>
      <v-date-picker
        :value="$utils.fromBrDatetime(value)"
        @input="
          $emit('input', $utils.formatDate($event));
          dateMenu = false;
        "
        :disabled="disabled"
        color="secondary"
        locale="pt-br"
        no-title
        show-adjacent-months
      >
        <v-spacer />
        <v-btn
          text
          color="secondary"
          @click="dateMenu = false"
        >
          fechar
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DateField",

  props: {
    value: {required: true},
    label: {required: true, type: String},
    disabled: {default: false, type: Boolean},
    clearable: {default: false, type: Boolean},
    rules: {default: undefined, type: Array},
  },

  data: () => ({
    dateMenu: false,
  }),

}
</script>
